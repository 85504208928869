module.exports = {
  language: {
    name: '中文',
    short: 'en_US'
  },
  logoName: 'Vendor',
  asin: 'Asin Input',
  selectAnchor: 'Select Anchor',
  selectBrand: 'Select Brand',
  chinaTime: 'BJT',
  localTime: 'Local Time',
  localData: 'Local Date',
  typeLive: 'Streaming',
  endLive: 'Live finished',
  notStartLive: 'Live soon',
  watchLive: 'Watch',
  selectShop: 'Select Shop',
  selectMonth: 'Select Month',
  brokerage: 'Commission',
  affirm: 'Confirm',
  confirmed: 'Confirmed',
  download: 'Download',
  particulars: 'Details',
  account: 'Account Balance',
  addShop: 'Product added in this live',
  popconfirmMessage: 'You can not change the amount after confirming, are you sure this is correct?',
  languMsg: 'Select Language',
  beginTime: 'Begin Time',
  endTime: 'End Time',
  calendar: {
    numLives: 'Live Created This Month ',
    lived: 'Already Streamed ',
    session: 'Total Sessions ',
    sessioning: 'Already Streamed ',
    shopNums: 'Product Number '
  },
  setting: {
    crmSetting: 'System Setting',
    password: 'Password',
    languageType: 'Language',
    updatePassword: 'Change Password',
    save: 'Save',
    quitLogin: 'Log Out',
    quitMsg: 'Are you sure to log out?',
    platform: 'Select System',
    amazon: 'Amazon Merchant',
    wahool: 'Vendor',
    enter: 'Enter',
    serviceSystem: 'System',
    serviceSystem1: 'System',
    serviceSystem2: 'System'
  },
  paswordSet: {
    oldInputMsg: 'Your New Password',
    newInputMsg: 'Confirm New Password',
    verifyOldMsg: 'Old Password',
    verifyNewMsg: 'Confirm New Password',
    oldNewOvifyMsg: 'Two Passwords Don’t Match'
  },
  login: {
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    userNamePla: `Test{'@'}****company name`,
    userNamePlas: 'Please enter the username',
    passwordPla: 'Please enter the password',
    login: 'Log In',
    amazonLogin: 'Log in and Authorize',
    amazonLoginTitle: 'Connect With Your Amazon Store',
    selectStoreTitle: 'Select the store you want to authorize',
    selectedText: 'Selected',
    selectedNum: 'Stores',
    modalCancel: 'Cancel',
    modalConfirm: 'Confirm'
  },
  tablement: {
    month: 'Month',
    settlement: 'Settlement Interval',
    mentType: 'Settlement Type',
    shopName: 'Shop',
    sellNums: 'Sales Pieces',
    sellPrice: 'Sales Amount',
    returnNums: 'Return Pieces',
    returnPrice: 'Refund Amount',
    settlementPrice: 'Settlement Price',
    operate: 'Operate'
  },
  detTab1: {
    merchant: 'Merchant',
    shop: 'Shop',
    localData: 'Local Date',
    localTime: 'Local Time',
    livelink: 'Live Link',
    pit: 'Spot',
    pitPrice: 'Spot Fee',
    brand: 'Brand/ASIN',
    clickrate: 'Click Rate'
  },
  detTab2: {
    month: 'Month',
    dataTime: 'Date',
    shop: 'Shop',
    brand: 'Brand',
    commissionType: 'Commission%',
    commission: 'Commission'
  },
  chargeTable: {
    enterPrice: 'Transaction Date',
    accountType: 'Settlement Type',
    accountCycle: 'Settlement Interval',
    remitPrice: 'Remittance Amount($)',
    entryPrice: 'Received Amount($)',
    expendPrice: 'Paid Amount($)',
    subtotalPrice: 'Subtotal($)'
  },
  search: 'Search',
  reset: 'Reset',
  view: 'View',
  edit: 'Edit',
  confirm: 'Confirm',
  cancel: 'Cancel',
  submit: 'Submit',
  noData: 'No Data',
  opSuccess: 'Success~',
  addressInfo: {
    receiver: ' Consignee',
    phone: 'Phone',
    postal: 'Zip code',
    address: 'Address'
  },
  components: {
    Pagination: {
      total: 'Total',
      page: 'Page',
      datas: 'Data'
    },
    AddExpressModal: {
      title: 'Add Shipping',
      expressName: 'Shipping Company',
      expressNameValidatorText: 'Add Shipping Company',
      expressCode: 'Tracking Number',
      expressCodeValidatorText: 'Add Tracking Number',
      remark: 'Remark',
      remarkValidatorText: 'Add Remark'
    },
    OperationLogModal: {
      title: 'Operation Log'
    }
  },
  orderManage: {
    filterForm: {
      detailSearchContent: 'Order Number/Name/Code'
    },
    tabsPane: {
      1: 'Pending Order',
      2: 'Unfulfilled Order',
      3: 'Fulfilled Order ',
      4: 'Cancelled Order'
    },
    paymentTypes: {
      1: 'Cash',
      2: 'Credit'
    },
    typeList: {
      1: 'Pending',
      2: 'Objection',
      3: 'Confirmed'
    },
    table: {
      productPicList: 'Product',
      orderSn: 'Order Number',
      paymentType: 'Payment Type',
      dissentReceiptAt: 'Delivery Time',
      cancelAt: 'Cancel time',
      quantity: 'Quantity',
      cancelQuantity: 'Cancel Quantity',
      deliveryQuantity: 'Shipped Quantity',
      dissentAmount: 'Payment Total',
      amount: 'Amount',
      expressFees: 'Shipping Fee (¥)',
      shippingCount: 'Shipment Batch',
      finishAt: 'Complete Time',
      status: 'Status',
      cancelReason: 'Cancel Reason',
      operate: 'Operate',
      receive: 'Accept Order',
      delivery: 'Ship',
      log: 'Log',
      overdue: 'Overdue',
      day: 'Day',
      shippingCountTips1: 'Shipped',
      shippingCountTips2: 'batch',
      receiptQuantity: 'Receipt Quantity',
      typeValue: 'Purchase Type',
      remark: 'remark'
    }
  },
  orderManageDetail: {
    tips: 'Reminder：Please confirm the supply price and delivery time before accepting orders.',
    purchaseQuantity: 'Purchase quantity（piece）',
    purchaseAmount: 'Purchase Amount（¥）',
    expressFees: 'Shipping Fee (¥)',
    expressFeesTips: 'Edit shipping fee',
    ReceiveOrderTable: {
      receiveOrder: 'Accept Order',
      raiseObjection: 'Apply Objection',
      objectionSubmit: 'Submit Objection',
      objectionSubmitTips: 'Confirm Objection Details',
      download: 'Export',
      table: {
        cancelQuantity: 'Cancellation quantity',
        quantityIncomGoods: 'quantity of incoming goods',
        productPicTemplate: 'Picture',
        productName: 'Product Name',
        sku: 'SKU',
        productParams: 'Variant',
        manufacturingNo: 'Manufacturer number',
        initPurchasePrice: 'Price(￥)',
        initQuantity: 'Quantity',
        unitPrice: 'Unit Price',
        bargain: 'Bargain',
        demand: 'Demand',
        canSent: 'Able to send',
        dissentPriceCheckboxText: 'Same price on the product',
        dissentQuantityCheckboxText: 'Same amount on the product',
        pleaseInput: 'please input'
      },
      dissentReceiptAt: 'Delivery time',
      dissentReceiptAtValidatorText: 'Select delivery time',
      confirmReceiveOrder: 'Comfirm accept order'
    },
    DeliveryTable: {
      tabsPane: {
        1: 'Unshipped',
        2: 'Shipped'
      },
      shippingStatus: {
        1: 'Unshipped',
        2: 'Shipped',
        3: 'Partially Shipped',
        4: 'Completed',
        5: 'Cancel Shipment'
      },
      expressStatus: {
        1: 'Underlivered',
        2: 'Delivered'
      },
      addressNum: 'Please fill in the tracking number',
      expressAddLabel: 'Add shipping',
      oneClickDelivery: 'Delivery',
      shippingStatusLabel: 'Deliver status',
      completion: 'Stock up completed',
      printTheStockList: 'Print the stock list',
      import: 'Import details',
      importTips1: 'File format incorrect,please upload in xls or xlsx.',
      importTips2: 'No data in file',
      download: 'Export unshipped details',
      table: {
        productPicTemplate: 'Picture',
        productName: 'Product info',
        shippedQuantity: 'Goods waiting to be sent',
        sku: 'SKU',
        productParams: 'Variant',
        manufacturingNo: 'Manufacturer number',
        dissentQuantity: 'Quantity',
        deliveryQuantity: 'Shipped Quantity',
        verifiedQuantity: 'Verified quantity',
        printTimes: 'print time',
        dissentQuantityTemp: 'Shipped items',
        receiptQuantity: 'Received Quantity',
        unqualifiedQuantity: 'Checked Quantity',
        overdue: 'Overdue',
        dissentQuantityTips1: 'The max quantity of this shipment is',
        dissentQuantityTips2: 'The shipment quantity has to be more than 0',
        unit: 'Unit',
        unReceive: 'Unreceived',
        receive: 'Received',
        qualified: 'Qualified',
        unqualified: 'Unqualified'
      }
    }
  },
  deliveryManage: {
    filterForm: {
      shippingSn: 'Name/Code',
      expressCode: 'Tracking Number',
      orderTitle: 'Invoice number, order number'
    },
    tabsPane: {
      1: 'Unshipped',
      2: 'Shipped',
      3: 'Completed'
    },
    table: {
      productPicList: 'Product',
      shippingSn: 'Shipment Number',
      receivingWarehouseName: 'Receiving Warehouse',
      deliveryQuantity: 'Shipping Quantity',
      createAt: 'Create Time',
      deliveryTime: 'Shipping Time',
      deliveryData: 'Delivery date',
      createName: 'Consigner',
      expressListBos: 'Tracking Info',
      operate: 'Operate',
      delivery: 'Shipment',
      print: 'Print' /* Print Shipping List */,
      send: 'Shipped',
      receive: 'Received',
      unqualified: 'Unqualified'
    }
  },
  deliveryManageDetail: {
    filterForm: {
      nameCode: 'Name/Code'
    },
    expressStatus: {
      1: 'Underlivered',
      2: 'Delivered'
    },
    delivery: 'Confirm Shipment',
    deliveryTips: 'Confirm Shipment Shipping List',
    print: 'Print Shipping List',
    printStockList: 'Print the stock list',
    download: 'Export Shipping Details',
    addExpress: 'Add Delivery',
    table: {
      deliveryTime: 'delivery time',
      receiptTime: 'arrival time',
      purchaseOrderSn: 'order number',
      productPicTemplate: 'Picture',
      productName: 'Product Name',
      sku: 'SKU',
      productParams: 'Variant',
      quantityToShipped: 'Quantity to be shipped',
      manufacturingNo: 'Manufacturer number',
      deliveryQuantity: 'Quantity',
      receiptQuantity: 'Received Quantity',
      qualifiedQuantity: 'Checked Quantity',
      unReceive: 'Unreceived',
      remark: 'remark',
      receive: 'Received',
      qualified: 'Qualified',
      unqualified: 'Unqualified'
    },
    deliveryList: 'Shipping List',
    stockList: 'Stock list',
    printTime: 'Print time',
    purchaseAmount: 'Purchase number'
  },
  returnManage: {
    filterForm: {
      name: 'Product Name/ID/SKU',
      expressCode: 'Tracking Number',
      returnName: 'Returner’s Name',
      returnNumber: 'Returned Order ID'
    },
    tabsPane: {
      1: 'To Be Confirmed',
      2: 'To Be Sent Back',
      3: 'To Be Signed',
      4: 'To Be Paid',
      5: 'Completed',
      6: 'Cancelled'
    },
    table: {
      returnNumber: 'Returned Order ID',
      typeDesc: 'Type',
      waitHour: 'Waiting Time',
      confirmAt: 'Confirmation Time',
      returnGoodsQuantity: 'Returned Quantity',
      lastReturnAmount: 'Refund (¥)',
      expressFees: 'Shipping Fee (¥)',
      waitPayAmount: 'Refund Pending (¥)',
      processingStateDesc: 'Status',
      createAt: 'Creation Time',
      logisticsInfor: 'Shipping Information',
      // returnName: 'Returner’s Name',
      cancelUid: 'Cancelled by',
      remark: 'Remark',
      createAndOutbound: 'Creation/Outbound Time',
      createAndFinish: 'Creation/Completion Time',
      createAndCancel: 'Creation/Cancellation Time',
      returnReason: 'Application Type',
      returnReasonTypeName: 'Reasons for Return',
      operate: 'Operate',
      agreeSendBack: 'Accept',
      rejectSendBack: 'Reject',
      log: 'Records',
      confirmReceipt: 'Confirm Receipt'
    }
  },
  returnManageDetail: {
    pageTitle: 'Return Details',
    navMessage: {
      returnType: 'Return Type',
      returnQuantity: 'Return Quantity',
      refundableAmount: 'Refundable',
      freightAmount: 'Shipping Fee',
      amountRefunded: 'Refunded',
      amountPayable: 'Payment Pending',
      recipients: 'Recipient',
      phone: 'Phone',
      address: 'Address',
      postalCode: 'Postal Code'
    },
    filterForm: {
      goodsKeywords: 'Supplier ID/SKU/SPU',
      purchaseSnKeywords: 'Order/Channel ID',
      defectKeywords: 'Defects'
    },
    btns: {
      agree: 'Accept',
      refuse: 'Reject',
      payment: 'Payment',
      confirmReceipt: 'Confirm Receipt',
      download: 'Export'
    },
    table: {
      pic: 'Picture',
      size: 'Variants',
      returnNum: 'Returned Quantity',
      purchasePrice: 'Purchase Price (¥)',
      returnPrice: 'Refund (¥)',
      defect: 'Defects',
      code: 'Code',
      orderSn: 'Orders/Channel ID',
      returnReason: 'Application Type',
      returnReasonTypeName: 'Reason for Return',
      operate: 'Operate'
    },
    tableList: {
      compilation: 'Supplier ID',
      attribute: 'Attribute',
      returnable: 'Returnable',
      retreat: 'Returned',
      needToReturn: 'Refundable',
      returnablePrice: 'Refund',
      showDetail: 'Detail',
      purchase: 'Purchase',
      channel: 'Channel'
    },
    modalTit: {
      agreeTit: 'Return Confirmation ',
      agreeDesc: 'Please confirm on return?',
      confirmReceiptTit: 'Confirm Receipt',
      confirmReceiptDesc: 'Please confirm if you have received the goods. Confirmed means you have received the goods!',
      refuseTit: 'Reason for Rejection',
      refusePlaceholder: 'Please enter reason for rejection',
      qualityTit: '	Quality Details',
      uniqueCode: 'Code',
      defect: 'Defects',
      defectRemark: 'Inspection Remarks'
    }
  },
  goodsManage: {
    filterForm: {
      nameSin: 'SKU/SPU/Style',
      productName: 'Product Name'
    },
    tabsPane: {
      1: 'Listing',
      2: 'Pending',
      3: 'Unlisted',
      4: 'All',
      5: 'Archived'
    },
    table: {
      pic: 'Picture',
      spu: 'SPU',
      colorName: 'Color',
      sizes: 'Size',
      price: 'Supply price',
      storage: 'Stock',
      hh: 'List Time',
      createAt: 'Create Time',
      ll: 'Unlist Time',
      productName: 'Prodcuct Name',
      // jj: '库存',
      // kk: '推送时间',
      state: 'Status',
      operate: 'Operate',
      log: 'Log',
      styleCode: 'Style'
    }
  },
  paymentDeductBill: {
    filterForm: {
      orderNumber: 'Order Number',
      methods: 'Methods',
      associatedNumber: 'Associated Number',
      startTime: 'Start Time',
      endTime: 'End Time',
      type: 'Type',
      classify: 'Classify',
      paymentType: 'Payment Type'
    },
    methodsList: {
      1: 'Return Order',
      2: 'Purchasing Order',
      3: 'Settlement Cycle'
    },
    typeList: {
      1: 'Deduct Money',
      2: 'Replenishment'
    },
    classifyList: {
      1: 'Freight Deduction',
      2: 'Freight Supplement',
      3: 'Other Deductions',
      4: 'Other Supplements'
    },
    paymentTypes: {
      1: 'Cash',
      2: 'Credit'
    },
    tabsPane: {
      1: 'To Be Processed',
      2: 'Processed',
      3: 'Rejected'
    },
    table: {
      number: 'Supplementary Deduction Order Number',
      classify: 'Classification Of Supplementary Deduction',
      paymentType: 'paymentType',
      money: 'Deduction Money',
      methods: 'methods',
      originalType: 'originalType',
      associatedNumber: 'associatedNumber',
      remark: 'remark',
      accessories: 'accessories',
      type: 'type',
      operator: 'operator',
      applicationTime: 'applicationTime',
      confirmationCertificate: 'confirmationCertificate',
      completionTime: 'completionTime',
      download: 'Export Refund Details'
    }
  },
  route: {
    orderManagement: {
      orderManagement: 'Order Center',
      orderManage: 'Order List',
      orderManageDetail: 'Order Details',
      shippingDetails: 'One-click shipping details',
      deliveryManage: 'Shipping List',
      deliveryManageDetail: 'Shipping Details',
      returnManage: 'Return List',
      returnManageDetail: 'Return Details'
    },
    goodsManagement: {
      goodsManagement: 'Product Center',
      goodsManage: 'Product Management'
    },
    financialManagement: {
      financialManagement: 'Financial Center',
      paymentDeductBill: 'Payment Deduct Bill'
    }
  }
}
